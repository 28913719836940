import { formatCurrency, isValidCEP } from '@brazilian-utils/brazilian-utils';
import Link from 'next/link';
import { useEffect } from 'react';
import { useWindowSize } from 'usehooks-ts';
import { useCartStore } from '#/common/stores/cart/CartStoreProvider';
import { ScrollArea } from '#/ui/scroll-area';
import { cn } from '#/utils/cn';
import { trpc } from '#/utils/trpc';
import { Icon } from '../Icon';
import { ImageWithFallback } from '../ImageWithFallback';
import { InputQuantity } from '../InputQuantity';
import { Button } from '../ui/button';
import { CartContainer } from './CartContainer';
import { CartCoupon } from './CartCoupon';
import { CartFooter } from './CartFooter';
import { CartFreight } from './CartFreight';

export function CartProducts() {
  const { width } = useWindowSize();

  const {
    products,
    removeProductFromCart,
    updateProductQuantityOnCart,
    updateProductsOnCart,
    setCurrentStep,
    currentStep,
    freight,
    setFreight,
    totalAmountCart,
    inStorePickup,
    productsCartIds,
  } = useCartStore((state) => ({
    inStorePickup: state.inStorePickup,
    removeProductFromCart: state.removeProductFromCart,
    updateProductQuantityOnCart: state.updateProductQuantityOnCart,
    products: state.productsOnCart,
    currentStep: state.currentStep,
    setCurrentStep: state.setCurrentStep,
    updateProductsOnCart: state.updateProductsOnCart,
    productsCartIds: state.productsOnCart.map(([product_id]) => product_id).sort(),
    freight: state.freight,
    setFreight: state.setFreight,
    totalAmountCart: state.totalAmountCart,
  }));

  const {
    data: productsData,
    isSuccess: areProductsLoaded,
    isFetching: isProductsLoading,
  } = trpc.productRouter.listProductsByIds.useQuery(
    { product_ids: productsCartIds },
    { enabled: productsCartIds.length > 0 },
  );

  const { data: calculatedFreight, isFetching: isFreightLoading } =
    trpc.freightRouter.calculateFreight.useQuery(
      {
        products: products.map(([product_id, props]) => ({
          id: product_id,
          quantity: props.quantity,
          metric: props.metric,
        })),
        postcode: freight.shippingPostcode || '',
        total: totalAmountCart,
      },
      {
        enabled:
          !inStorePickup &&
          freight.freightPrice !== 'off_topic' &&
          isValidCEP(freight.shippingPostcode || '') &&
          products.length > 0 &&
          totalAmountCart > 0,
      },
    );

  useEffect(() => {
    if (calculatedFreight) {
      setFreight({
        freightPrice: calculatedFreight.price,
        shippingPostcode: freight.shippingPostcode,
      });
    }
  }, [calculatedFreight, setFreight, freight.shippingPostcode]);

  useEffect(() => {
    if (productsData && areProductsLoaded) {
      updateProductsOnCart(productsData);
    }
  }, [productsData, areProductsLoaded, updateProductsOnCart]);

  const handleQuantityChange = (quantity: number, productId: string) => {
    updateProductQuantityOnCart({ product_id: productId, quantity });
  };

  const handleProceedToCheckout = () => {
    setCurrentStep(currentStep + 1);
  };

  if (products.length === 0) {
    return (
      <CartContainer>
        <div className="container h-full w-full">
          <div className="mx-auto mt-10 flex w-fit flex-col items-center gap-1">
            <Icon
              name="shopping-cart"
              size={120}
              strokeWidth={1.2}
              className="mx-auto mb-2 opacity-20"
            />
            <p className="w-fit text-center text-sm opacity-40">
              Não há nenhum item no carrinho
            </p>
          </div>
        </div>
      </CartContainer>
    );
  }

  return (
    <>
      <CartContainer>
        <p className="cursor-default pb-4 text-center text-sm opacity-50 md:text-base dark:text-white dark:opacity-90">
          {products.length} {products.length > 1 ? 'itens' : 'item'}
        </p>
        <ScrollArea className="flex-1 overflow-y-auto pr-4">
          <div className="mx-1 divide-y pt-1.5">
            {products.map(([productId, props]) => (
              <div
                key={productId}
                className="flex w-full gap-3 py-3 sm:gap-4"
              >
                <Link
                  href={`/catalogo/produto/${props.slug}`}
                  className="min-h-[80px] min-w-[80px] overflow-hidden rounded-md"
                  prefetch={false}
                >
                  <ImageWithFallback
                    src={props.image.src}
                    alt={props.image.alt}
                    productName={props.title}
                    width={120}
                    height={120}
                    className="rounded-md object-contain transition-transform duration-300 hover:scale-110"
                  />
                </Link>
                <div className="flex w-full flex-col justify-between gap-1">
                  <div className="flex justify-between gap-2">
                    <div className="cursor-default">
                      <p className={cn('text-[13px]', width > 480 && 'text-sm')}>
                        {props.title}
                      </p>
                      <p className="text-xs opacity-60">Ref.: {props.reference}</p>
                    </div>
                    <Button
                      variant="ghost"
                      size="icon"
                      aria-label="Remover produto"
                      onClick={() => removeProductFromCart(productId)}
                      title="Remover item do carrinho"
                    >
                      <Icon
                        name="trash"
                        size={16}
                        className="text-red-600"
                      />
                    </Button>
                  </div>
                  <div
                    className={cn(
                      'flex flex-col items-end justify-between sm:items-center',
                      width > 470 && 'flex-row items-center',
                    )}
                  >
                    <p
                      className={cn(
                        'w-fit font-semibold text-brand-main text-sm dark:text-brand-dark',
                        width > 470 && 'text-base',
                      )}
                    >
                      R$ {formatCurrency(props.quantity * props.actual_price_number)}
                    </p>
                    <InputQuantity
                      value={props.quantity}
                      className="w-[136px]"
                      minimum={props.minimum}
                      step={props.step}
                      onChangeValue={(value) => handleQuantityChange(value, productId)}
                      size={width > 480 ? 'default' : 'small'}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </ScrollArea>
        <CartFreight isPending={isFreightLoading} />
        <CartCoupon />
      </CartContainer>
      <CartFooter
        isLoading={isProductsLoading || isFreightLoading}
        onSubmit={handleProceedToCheckout}
      />
    </>
  );
}
